import React, { useRef, useEffect, useState } from 'react';
import css from './LangPicker.module.css';
import cx from 'classnames';
import grayGlobe from '@/assets/languageGray.svg';
import blueGlobe from '@/assets/languageBlue.svg';
import yellowGlobe from '@/assets/languageYellow.svg';
import arrowDown from '@/assets/colorable/caretDown.svg';
import arrowUp from '@/assets/caretUpBlue.svg';
import arrowUpYellow from '@/assets/caretUpYellow.svg';
import { getSafeDeep } from '@/util/state';
import DropdownList from '@/src/components/DropdownList/DropdownList';
import { useTranslation } from '@/util/helper';

export function useOutsideAlerter(ref, isOpen, setIsOpen) {
    function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target) && isOpen) {
            setIsOpen(false);
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    });
}

function LangPicker(props) {
    const wrapperRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const { t } = useTranslation();

    const toggle = () => {
        setIsOpen(!isOpen);
    };

    useOutsideAlerter(wrapperRef, isOpen, setIsOpen);

    let icon = isOpen ? blueGlobe : grayGlobe;
    icon = props.dark ? yellowGlobe : icon;

    let arrow = isOpen ? arrowUp : arrowDown;
    arrow = isOpen && props.dark ? arrowUpYellow : arrow;

    const { selected, select, options, footerAlign } = props;

    return (
        <div className={cx(css.wrapper, props.className)} ref={wrapperRef}>
            <select
                value={getSafeDeep(selected, 'code', 'en')}
                onChange={(e) => {
                    select(options.find((lang) => lang.code == e.target.value));
                }}
                className={cx(css.mobileSelect)}>
                <option value="" disabled key="choose">
                    {t('language.chooseLanguage')}
                </option>
                {options.map((option) => (
                    <option key={option.code} value={option.code}>
                        {option.native}
                    </option>
                ))}
            </select>

            <button className={css.selector} onClick={toggle}>
                <div className={css.valueWrapper}>
                    <img className={css.icon} src={icon} />
                    <div className={css.value}>
                        {getSafeDeep(selected, 'code', 'EN').toUpperCase()}
                    </div>
                </div>
                <div className={css.arrow}>
                    <img src={arrow} />
                </div>
            </button>
            <DropdownList
                className={{ [css['dropdown-list']]: footerAlign }}
                select={(value) => {
                    setIsOpen(false);
                    select(value);
                }}
                options={options}
                selected={selected}
                valueKey="code"
                labelKey="native"
                isOpen={isOpen}
            />
        </div>
    );
}

export default LangPicker;
